import React, { useEffect, useRef, useState } from "react";
import { iTransaction } from "../../app/interfaces/iTransaction";

type props = {
  transaction: iTransaction;
  onAction: any;
};
function OptionButton({transaction, onAction}: props) {  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleAction = (action: string) => {
    onAction(action, transaction)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);
  
  return (
    <div className="relative flex items-center">
    {/* Three-dot menu button */}
      {(
        <button
        ref={buttonRef}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="p-1 rounded-full hover:bg-gray-200 ml-2"
        >
          <svg
            className="w-5 h-5 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </button>
      )}
      {/* Dropdown menu */}
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 mt-8 w-20 bg-white rounded-md shadow-lg py-1 z-10"
        >
          <button
            onClick={() => {
              handleAction("edit");
              setIsMenuOpen(false);
            }}
            className="block w-full px-2 py-1 text-xs text-gray-700 hover:bg-gray-100"
          >
            Edit
          </button>
          <button
            onClick={() => {
              handleAction("delete");
              setIsMenuOpen(false);
            }}
            className="block w-full px-2 py-1 text-xs text-gray-700 hover:bg-gray-100"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
}
export default OptionButton;
