import UserLayout from "../../layouts/UserLayout";
import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectExpense } from "../../features/xpenseSlice";
import AddIncome from "./AddIncome";
import { Fragment } from "react";
import { selectEntity } from "../../features/entitySlice";
import { iEntity } from "../../app/interfaces/iEntityState";
import { selectAuth } from "../../features/authSlice";
import { selectIncome } from "../../features/incomeSlice";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";
import Currency from "../../components/Currency";
import { iTransaction } from "../../app/interfaces/iTransaction";
import MonthlyIncomeWidget from "../../components/MonthlyIncomeWidget";
import RecurringIncomeWidget from "../../components/RecurringIncomeWidget";

const Income = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const expenseList = useSelector(selectExpense);

  let incomeList: iTransaction[] = expenseList.expenses;
  incomeList = incomeList.filter((item) => {
    return item.type === "income";
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const income = useSelector(selectIncome);
  const entity = useSelector(selectEntity);

  const [incomeSource, setIncomeSource] = useState<iIncomeSource[]>(
    income.incomeSource || []
  );

  const [entities, setEntities] = useState<iEntity[]>(entity.entities || []);

  useEffect(() => {
    if (!auth.reload) {
      navigate("/auth");
    }
    onProgress(100);
  }, []);

  return (
    <UserLayout>
      <Fragment>
        <div className="w-full">
          <div className="w-full">
            <div className="text-right pt-1 md:pt-0 mx-1 md:ml-3">
              <Button
                type="button"
                color="blue"
                disabled={open}
                onClick={handleOpen}  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                New Income
              </Button>
            </div>
            <AddIncome
              showNewExpense={open}
              setShowNewExpense={handleOpen}
              incomeSource={incomeSource}
              entities={entities}
              onProgress={onProgress}
            ></AddIncome>
          </div>

          <div className="w-full flex flex-wrap justify-between">
            {/* Left */}
            <div className="w-full md:w-2/6 py-2 md:pr-2.5 md:py-3">
              <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
                <MonthlyIncomeWidget
                  incomeSource={incomeSource}
                  expenseList={incomeList}
                />
              </div>

              <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
                <RecurringIncomeWidget
                  showNewExpense={open}
                  setShowNewExpense={handleOpen}
                  incomeSource={incomeSource}
                  entities={entities}
                  onProgress={onProgress}

                />
              </div>
            </div>

            {/* Right */}
            <div className="w-full md:w-4/6 py-2 md:pl-2.5 md:py-3">
              <div className="w-full">
                <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
                  <div className="text-titleColor  ">
                    Recent incomes
                  </div>
                  <div className="md:pt-5">
                    <div>
                      <table className="w-full table-auto">
                        <thead className="hidden md:table-header-group">
                          <tr className="text-left border-b-2">
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Date
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Note
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Income Source
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Payment Method
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {incomeList && incomeList.length > 0 ? (
                            incomeList.map((item, i) => (
                              <tr
                                className="border-b-2 sm:border-b-gray-100 md:border-b-gray-100 flex flex-col md:table-row"
                                key={i}
                              >
                                <td className="pt-5 md:py-4 text-sm text-gray-700 text-md pr-5">
                                  {item.formatted_created_at}
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 text-md flex justify-between md:table-cell">
                                  <span className="md:hidden">Note</span>
                                  <span> {item.note}</span>
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 text-md md:pr-5 flex justify-between md:table-cell">
                                  <span className="md:hidden">
                                    Income Source
                                  </span>
                                  <span className="">
                                    {item.income_source.name}
                                  </span>
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 text-md flex justify-between md:table-cell">
                                  <span className="md:hidden">
                                    Payment Method
                                  </span>
                                  <span> {item.financial_entity.name}</span>
                                </td>
                                <td className="pb-4 md:py-4 text-sm text-gray-700 text-md flex justify-between md:table-cell">
                                  <span className="md:hidden">Amount</span>
                                  <span>
                                    <Currency val={Number(item.amount)} />
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={5}
                                className="text-center p-5 text-gray-600 "
                              >
                                no records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </UserLayout>
  );
};

export default Income;
