import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { iExpenseType } from "../app/interfaces/iExpenseState";
import { iTransaction } from "../app/interfaces/iTransaction";
import { iWidgetItem } from "../app/interfaces/iWidget";
import { toFullMonth, toMonth } from "../constants/date";
import Currency from "./Currency";
import useTransaction from "../app/hooks/useTransaction";
import { useSelector } from "react-redux";
import { selectExpense } from "../features/xpenseSlice";

type props = {
  expenseList: iTransaction[];
  exTypesList: iExpenseType[];
};

function MonthlyExpenseWidget({ expenseList, exTypesList }: props) {
  const [widgets, setWidgets] = useState<iWidgetItem[]>([]);
  const [total, setTotal] = useState(0);
  const month = new Date().getUTCMonth();
  const year = new Date().getUTCFullYear();
  const thisYear = new Date().getUTCFullYear();
  const [currentMonth, setCurrentMonth] = useState(month);
  const [currentYear, setCurrentYear] = useState(year);
  let total_ = 0;
  const [loadTransactions] = useTransaction();
  const expense = useSelector(selectExpense);
  const [reloadEffect, setReloadEffect] = useState(false);

  useEffect(() => {
    // fetching transactions from different year
    if (!expense.transactionYearsInState.includes(currentYear)) {
      loadTransactions(currentYear).finally(() => {
        setReloadEffect(!reloadEffect);
      });
    }
  }, [currentYear]);

  useEffect(() => {
    let subTotal = 0;
    setWidgets([]);
    total_ = 0;

    exTypesList.map((item) => {
      subTotal = 0;
      expenseList.map((eItem) => {
        const createdAt = new Date(eItem.created_at);
        if (
          item.id === eItem.expense_type_id &&
          createdAt.getUTCMonth() === currentMonth &&
          createdAt.getUTCFullYear() === currentYear
        ) {
          subTotal = subTotal + Number(eItem.amount);
        }
      });
      if (subTotal > 0) {
        const i: iWidgetItem = {
          name: item.name ? item.name : "",
          val: subTotal,
        };
        setWidgets((arr) => [...arr, i]);
        total_ = total_ + subTotal;
      }
    });
    setTotal(total_);
  }, [currentMonth, currentYear, reloadEffect, expenseList]);

  const nextMonth = () => {
    if (currentMonth + 1 < 12) setCurrentMonth(currentMonth + 1);
    else {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    }
  };

  const prevMonth = () => {
    if (currentMonth > 0) setCurrentMonth(currentMonth - 1);
    else {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    }
  };

  return (
    <div>
      <div className="text-titleColor  flex flex-row justify-between">
        <div title={toFullMonth[new Date().getMonth()]}>Monthly expenses</div>
        <div className=" text-xs text-gray-500 flex mt-1">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="mr-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
            onClick={() => prevMonth()}
          />
          <div className="text-gray-800 p-0.5 select-none">
            {toMonth[currentMonth]}{" "}
            {thisYear !== currentYear && currentYear.toString().slice(-2)}
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="ml-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
            onClick={() => nextMonth()}
          />
        </div>
      </div>
      <div className="md:pt-4">
        <ul className="w-full text-gray-800">
          {widgets &&
            widgets.length > 0 &&
            widgets.map((item, i) => (
              <li
                className="flex flex-row justify-between my-2 pb-2 border-b-[1px] border-b-gray-200 text-gray-700 text-sm tracking-wide"
                key={i}
              >
                <div className="">{item.name}</div>
                <div>
                  <Currency val={item.val} />
                </div>
              </li>
            ))}

          {widgets.length > 0 ? (
            <li className="flex flex-row justify-between mt-5 pb-2 text-gray-700 text-sm tracking-wide">
              <div className="font-semibold">Total</div>
              <div className="font-semibold">
                <Currency val={total} />
              </div>
            </li>
          ) : (
            <p className="text-sm text-center text-gray-600 select-none">
              no expenses yet
            </p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default MonthlyExpenseWidget;
