import {
  faChevronLeft,
  faChevronRight,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { iIncomeSource } from "../app/interfaces/iIncomeState";
import { iTransaction } from "../app/interfaces/iTransaction";
import { iWidgetItem } from "../app/interfaces/iWidget";
import { toFullMonth, toMonth } from "../constants/date";
import Currency from "./Currency";

type props = {
  expenseList: iTransaction[];
  incomeSource: iIncomeSource[];
};

function MonthlyIncomeWidget({ expenseList, incomeSource }: props) {
  const [widgets, setWidgets] = useState<iWidgetItem[]>([]);
  const [total, setTotal] = useState(0);
  const month = new Date().getUTCMonth();
  const [currentMonth, setCurrentMonth] = useState(month);
  let total_ = 0;

  useEffect(() => {
    let subTotal = 0;
    setWidgets([]);
    total_ = 0;
    incomeSource.map((item) => {
      subTotal = 0;
      expenseList.map((eItem) => {
        const createdAt = new Date(eItem.created_at);
        if (
          item.id === eItem.income_source_id &&
          createdAt.getUTCMonth() === currentMonth &&
          createdAt.getUTCFullYear() === new Date().getUTCFullYear()
        ) {
          subTotal = subTotal + Number(eItem.amount);
        }
      });
      if (subTotal > 0) {
        const i: iWidgetItem = {
          name: item.name,
          val: subTotal,
        };
        setWidgets((arr) => [...arr, i]);
        total_ = total_ + subTotal;
      }
    });
    setTotal(total_);
  }, [currentMonth, expenseList]);

  return (
    <div>
      <div className="text-titleColor  flex flex-row justify-between">
        <div title={toFullMonth[new Date().getMonth()]}>Monthly Income</div>
        <div className=" text-xs text-gray-500 flex mt-1">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="mr-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
            onClick={() => {
              if (currentMonth > 0) setCurrentMonth(currentMonth - 1);
            }}
          />
          <div className="text-gray-800 p-0.5">{toMonth[currentMonth]}</div>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="ml-1 p-1 cursor-pointer hover:bg-gray-200 rounded-full"
            onClick={() => {
              if (currentMonth + 1 < 12) setCurrentMonth(currentMonth + 1);
            }}
          />
        </div>
      </div>
      <div className="md:pt-4">
        <ul className="w-full text-gray-800">
          {widgets &&
            widgets.length > 0 &&
            widgets.map((item, i) => (
              <li
                className="flex flex-row justify-between my-2 pb-2 border-b-2 border-b-gray-100 text-gray-700 text-sm tracking-wide"
                key={i}
              >
                <div className="">{item.name}</div>
                <div>
                  <Currency val={item.val} />
                </div>
              </li>
            ))}

          {widgets.length > 0 ? (
            <li className="flex flex-row justify-between mt-5 pb-2 text-gray-700 text-sm tracking-wide">
              <div className="font-semibold">Total</div>
              <div className="font-semibold">
                <Currency val={total} />
              </div>
            </li>
          ) : (
            <p className="text-sm text-center  text-gray-600">
              no income yet
            </p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default MonthlyIncomeWidget;
