import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iAuthState } from "../app/interfaces/iAuthState";
import { RootState } from "../app/store";


const initialState: iAuthState = {
  id: null,
  name: null,
  token: '',
  refreshToken: '',
  reload: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{id: string, name: string, token: string, refreshToken: string}>) => {
      localStorage.setItem('user', JSON.stringify({
        id: action.payload.id,
        name: action.payload.name,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        lastLogin: Date.now()
      }))
      state.id = action.payload.id
      state.name = action.payload.name
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
    },
    refreshUser: (state, action: PayloadAction<{token: string}>) => {localStorage.setItem('user', JSON.stringify({
      id: state.id,
      name: state.name,
      token: action.payload.token,
      refreshToken: state.refreshToken,
      lastLogin: Date.now()
    }))
      state.token = action.payload.token
    },
    setReload: (state) => {
      state.reload = true
    },
    logOut: (state) => {
      localStorage.clear()
      state.id = null
      state.name = null
      state.token = null
      state.refreshToken = null
    },
  }
})

export const selectAuth = (state: RootState) => state.auth
export const { setUser, logOut, refreshUser, setReload } = authSlice.actions
export default authSlice.reducer