import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SplashScreen from "../../components/SplashScreen";
import { selectAuth, setReload } from "../../features/authSlice";
import { setEntity } from "../../features/entitySlice";
import { setIncomeSource } from "../../features/incomeSlice";
import {
  setExpenseTypes,
  setExpenses,
  setTransactionYear,
} from "../../features/xpenseSlice";
import {
  setRecurring
} from "../../features/recurringSlice";
import UserLayout from "../../layouts/UserLayout";
import { useGetEntitiesMutation } from "../../services/entityApi";
import {
  useGetRecurringTransactionsMutation
} from "../../services/recurringApi";
import {
  useGetExpensesMutation,
  useGetExpenseTypesMutation,
} from "../../services/expenseApi";
import { useGetIncomeSourcesMutation } from "../../services/incomeApi";
import DueWidget from "../../components/DashboardComponents/DueWidget";
import CreditUsageWidget from "../../components/DashboardComponents/CreditUsageWidget";
import LoanStatusWidget from "../../components/DashboardComponents/LoanStatusWidget";
import MonthlyExpenseChart from "../../components/charts/MonthlyExpenseChart";

const Dashboard = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [getExpenseTypes, {}] = useGetExpenseTypesMutation();
  const [getExpenses, {}] = useGetExpensesMutation();
  const [getEntities, {}] = useGetEntitiesMutation();
  const [getIncomeSources, {}] = useGetIncomeSourcesMutation();
  const [getRecurringTransactions, {}] = useGetRecurringTransactionsMutation();

  const year = new Date().getUTCFullYear();

  const fetchData = async () => {
    onProgress(10);
    setLoading(true);
    const currentYear = new Date().getUTCFullYear();
    await getExpenseTypes({})
      .then((res) => {
        const data = "data" in res ? res.data : null;
        if (data) {
          dispatch(setExpenseTypes({ expenseTypes: data }));
        }
        onProgress(20);
      })
      .catch((err) => {
        console.log(err);
        onProgress(20);
      });

    await getEntities({})
      .then((res) => {
        const data = "data" in res ? res.data : null;
        if (data) {
          dispatch(setEntity({ entities: data }));
        }
        onProgress(40);
      })
      .catch((err) => {
        console.log(err);
        onProgress(40);
      });
    // get Expenses
    await getExpenses({ year: currentYear })
      .then((res) => {
        const data = "data" in res ? res.data : null;
        if (data) {
          dispatch(setExpenses({ expenses: data }));
          dispatch(setTransactionYear({ year: currentYear }));
        }
        onProgress(60);
      })
      .catch((err) => {
        console.log(err);
        onProgress(60);
      });
    //End Expense
    // get Recurring
    await getRecurringTransactions({ year: currentYear })
      .then((res) => {
        const data = "data" in res ? res.data : null;
        //console.log("getRecurring: ", data);
        if (data) {
          dispatch(setRecurring({rTxn: data}));
        }
        onProgress(70);
      })
      .catch((err) => {
        console.log(err);
        onProgress(70);
      });
    //End Recurring
    await getIncomeSources({})
      .then((res) => {
        //console.log("getIncomeSources: ", res);
        const data = "data" in res ? res.data : null;
        if (data) {
          dispatch(setIncomeSource({ incomeSource: data }));
        }
        onProgress(80);
      })
      .catch((err) => {
        console.log(err);
        onProgress(80);
      });
    await dispatch(setReload());
    onProgress(100);
    setLoading(false);
  };

  useEffect(() => {
    if (!auth.reload) {
      fetchData();
    } else {
      onProgress(100);
    }
  }, []);

  return !loading ? (
    <UserLayout>
      <div className={`w-full`}>
        <div className="w-full flex flex-wrap justify-between">

          <div className="w-full md:w-4/6 lg:w-4/6 md:px-1">
            <MonthlyExpenseChart />
          </div>

          <div className="w-full md:w-2/6 lg:w-2/6 md:px-1">
            <DueWidget />
            <CreditUsageWidget />
            <LoanStatusWidget />
          </div>
          
        </div>
      </div>
    </UserLayout>
  ) : (
    <SplashScreen />
  );
};

export default Dashboard;
