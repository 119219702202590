import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { iExpenseState, iExpenseType } from "../app/interfaces/iExpenseState"
import { iTransaction } from "../app/interfaces/iTransaction"
import { RootState } from "../app/store"


const initialState:iExpenseState = {
  expenseTypes: [],
  expenses:[],
  transactionYearsInState:[]
}

export const expenseSlice = createSlice({
  name: 'expense',
  initialState: initialState,
  reducers: {
    setExpenseTypes: (state, action: PayloadAction<{expenseTypes: iExpenseType[]}>) => {
      state.expenseTypes = action.payload.expenseTypes
    },
    addExpenseTypes: (state, action: PayloadAction<{expenseType: iExpenseType}>) => {
      state.expenseTypes.unshift(action.payload.expenseType)
    },
    editUpdateExpenseTypes: (state, action: PayloadAction<{expenseType: iExpenseType}>) => {
      const updatedExpenseTypes = state.expenseTypes.map((item, i) => {
        if(item.id === action.payload.expenseType.id) {
          item = action.payload.expenseType;
        }
        return item;
      })
      state.expenseTypes = updatedExpenseTypes;
    },
    
    setExpenses: (state, action: PayloadAction<{expenses: iTransaction[]}>) => {
      state.expenses = action.payload.expenses;
      //state.expenses = state.expenses.concat(action.payload.expenses);
    },
    
    extendExpenses: (state, action: PayloadAction<{expenses: iTransaction[]}>) => {
      state.expenses = state.expenses.concat(action.payload.expenses);
    },

    deleteExpense: (state, action: PayloadAction<{id: number}>) => {
      const expenses = state.expenses.filter((item) => {
        if(item.id !== action.payload.id) {
          return item;
        }
      })
      state.expenses = expenses;
    },
    
    addExpense: (state, action: PayloadAction<{expense: iTransaction}>) => {
      //Check for update
      if(state.expenses.filter((item) => item.id === action.payload.expense.id).length > 0) {
        //Update
        const updatedExpenses = state.expenses.map((item, i) => {
          if(item.id === action.payload.expense.id) {
            item = action.payload.expense;
          }
          return item;
        })
        state.expenses = updatedExpenses
      } else {
        //Add new
        state.expenses.unshift(action.payload.expense)
      }
    },
    
    setTransactionYear: (state, action: PayloadAction<{year: number}>) => {
      state.transactionYearsInState.push(action.payload.year);
    }
  }
})

export const selectExpense = (state: RootState) => state.expense
export const { setExpenseTypes, addExpenseTypes, setExpenses, addExpense, editUpdateExpenseTypes, setTransactionYear, extendExpenses, deleteExpense} = expenseSlice.actions
export default expenseSlice.reducer