import { RootState } from './../app/store';
import { MM_API_PROD, MM_API_DEV } from '../constants/APIs';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { logOut, refreshUser } from '../features/authSlice';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === 'production' ?  MM_API_PROD : MM_API_DEV,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    const refreshToken = (getState() as RootState).auth.refreshToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    } 
    if(refreshToken) {
      headers.set('authorization-refresh', `Bearer ${refreshToken}`)
    }
    return headers
  },
  credentials: 'include',
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  try {
    if (result.error && result.error.status === 401) {
      const refreshResult = await baseQuery({
          url: "/auth/refreshToken",
          method: "POST"
        }, api, extraOptions)
      if(refreshResult.data) {
        const res = <{access_token:string}>refreshResult.data
        api.dispatch(refreshUser({token: res.access_token}))
        result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch(logOut())
      }
    }
  } catch(e) {
    api.dispatch(logOut())
  }
  return result;
}