import { Button, Switch } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iExpenseType } from "../../app/interfaces/iExpenseState";
import { iEntity } from "../../app/interfaces/iEntityState";
import { useCreateTransferMutation } from "../../services/expenseApi";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { addExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { updateEntityForTransfer } from "../../features/entitySlice";
import ToggleSwitch from "../../components/ToggleSwitch";

interface IFormInput {
  from: string;
  to: string;
  amount: number;
  note: string;
  date: string;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  exTypes?: iExpenseType[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
};

function AddTransfer({
  showNewExpense,
  setShowNewExpense,
  exTypes,
  entities,
  onProgress,
}: Props) {
  const dispatch = useAppDispatch();
  const [createTransfer, { data, isSuccess, isError, isLoading, error }] =
    useCreateTransferMutation();
  const [fromEntityIdForEntityState, setFromEntityIdForEntityState] =
    useState(0);
  const [toEntityIdForEntityState, setToEntityIdForEntityState] = useState(0);
  const [amountForEntityState, setAmountForEntityState] = useState(0);
  const [repeatForm, setRepeatForm] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //console.log(data);
    setFromEntityIdForEntityState(Number(data.from));
    setToEntityIdForEntityState(Number(data.to));
    setAmountForEntityState(Number(data.amount));
    await createTransfer({
      from_entity_id: parseInt(data.from),
      financial_entity_id: parseInt(data.to),
      amount: data.amount,
      note: data.note,
      created_at: data.date,
    });
  };

  useEffect(() => {
    if (!repeatForm) {
      const today = new Date();
      setValue(
        "date",
        today.getUTCFullYear() +
          "-" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(today.getDate()).padStart(2, "0")
      );
    }
  }, [showNewExpense]);

  useEffect(() => {
    if (isSuccess) {
      if (!repeatForm) {
        reset();
      }
      //update state
      if (data) {
        const newData_: iTransaction = data[0] ? data[0] : null;
        dispatch(addExpense({ expense: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      // Update State of Financial Entity
      dispatch(
        updateEntityForTransfer({
          fromId: fromEntityIdForEntityState,
          toId: toEntityIdForEntityState,
          balance: amountForEntityState,
        })
      );
      onProgress(100);
      ToastSuccess(1, "Transfer added successfully");
    }
    if (isError) {
      ToastError(2, "Cannot add new transfer, please try again: " + Error);
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            New Transfer
          </DialogHeader>

          <DialogBody divider className="font-normal"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  From
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("from", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  {entities &&
                    entities.length > 0 &&
                    entities.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">{errors?.from?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  To
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("to", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  {entities &&
                    entities.length > 0 &&
                    entities.map(
                      (option) =>
                        option.id !== 0 && (
                          <option key={option.id} value={option.id.toString()}>
                            {option.name}
                          </option>
                        )
                    )}
                </select>
                <p className="text-red-900 text-right">{errors?.to?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Amount
                </label>
                <input
                  type="number"
                  step="0.01"
                  {...register("amount", {
                    required: true,
                    maxLength: 11,
                    pattern: /^\d*\.?\d*$/,
                  })}
                  pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                  className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider ${
                    "amount" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">
                  {errors?.amount?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Note
                </label>
                <textarea
                  id=""
                  {...register("note", { required: true, maxLength: 100 })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "note" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                ></textarea>
                <p className="text-red-900 text-right">{errors?.note?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  {...register("date", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.date?.type}</p>
              </div>
              <div className="mt-4 mb-2">
                <ToggleSwitch
                  label="Repeat"
                  onChange={() => setRepeatForm(!repeatForm)}
                  checked={repeatForm}
                />
              </div>
            </div>
          </DialogBody>
          <DialogFooter  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <span>Add</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddTransfer;
