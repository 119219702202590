import { extendExpenses, setExpenses, setTransactionYear } from "../../features/xpenseSlice";
import { useGetExpensesMutation } from "../../services/expenseApi";
import { useAppDispatch } from "../hooks";

export default function useTransaction() {
  const [getExpenses, {}] = useGetExpensesMutation();
  const dispatch = useAppDispatch();

  const loadTransactions = async (year:number) => {
    await getExpenses({ year: year })
    .then((res) => {
      // console.log('year', year);
      // console.log("getExpenses: ", res);
      const data = "data" in res ? res.data : null;
      if (data) {
        dispatch(extendExpenses({ expenses: data }));
        dispatch(setTransactionYear({ year: year }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return [loadTransactions];
}