import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const expenseApi = createApi({
  reducerPath: 'expenseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createExpenseType: builder.mutation({
      query: (body: {id: number, name:string, parent: number, in_trends: boolean}) => {
        return {
          url: '/v1/expense-type/add',
          method: 'post',
          body
        }
      }
    }),
    getExpenseTypes: builder.mutation({
      query: () => {
        return {
          url: '/v1/expense-type',
          method: 'get',
        }
      }
    }),
    createExpense: builder.mutation({
      query: (body: {id?:number, from_entity_id:number, expense_type_id:number, amount:number, note:string, created_at: string}) => {
        return {
          url: '/v1/expense/add',
          method: 'post',
          body
        }
      }
    }),
    removeExpense: builder.mutation({
      query: (body: {id:number}) => {
        return {
          url: '/v1/expense/remove',
          method: 'post',
          body
        }
      }
    }),
    getExpenses: builder.mutation({
      query: (body: { year: number}) => {
        return {
          url: '/v1/expense',
          method: 'post',
          body
        }
      }
    }),
    createTransfer: builder.mutation({
      query: (body: {from_entity_id:number, financial_entity_id:number, amount:number, note:string, created_at: string}) => {
        return {
          url: '/v1/transfer/add',
          method: 'post',
          body
        }
      }
    }),
  }),
})


export const { useCreateExpenseTypeMutation, useGetExpenseTypesMutation, useCreateExpenseMutation, useRemoveExpenseMutation, useGetExpensesMutation, useCreateTransferMutation } = expenseApi;