import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const recurringApi = createApi({
  reducerPath: 'recurringApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({

    createRecurringExpense: builder.mutation({
      query: (body: {financial_entity_id:number, expense_type_id:number, amount:number, note:string, created_at: string, recurring_type: number}) => {
        return {
          url: '/v1/recurring/expense/add',
          method: 'post',
          body
        }
      }
    }),
    updateRecurringExpense: builder.mutation({
      query: (body: {id:number, financial_entity_id:number, expense_type_id:number, amount:number, note:string, recurring_type: number}) => {
        return {
          url: '/v1/recurring/expense/add',
          method: 'post',
          body
        }
      }
    }),
    getRecurringTransactions: builder.mutation({
      query: (body: { year: number}) => {
        return {
          url: '/v1/recurring/transactions',
          method: 'post',
          body
        }
      }
    }),
    removeRecurringTransactions: builder.mutation({
      query: (body: { id: number}) => {
        return {
          url: '/v1/recurring/transaction/remove',
          method: 'post',
          body
        }
      }
    }),
    createRecurringIncome: builder.mutation({
      query: (body: {financial_type_id:number, income_source_id:number, amount:number, note:string, created_at: string, recurring_incomes: any[]}) => {
        return {
          url: '/v1/recurring/income/add',
          method: 'post',
          body
        }
      }
    }),
  }),
})


export const { useCreateRecurringExpenseMutation, useUpdateRecurringExpenseMutation, useGetRecurringTransactionsMutation, useRemoveRecurringTransactionsMutation, useCreateRecurringIncomeMutation } = recurringApi;