import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { selectEntity } from "../../features/entitySlice";
import AddEntity from "./AddEntity";
import { entityType } from "../../app/shared";
import { iEntity } from "../../app/interfaces/iEntityState";
import Currency from "../../components/Currency";

const formState = {
  name: "",
  type: "",
  balance: 0,
  credit_limit: 0,
  apr: 0,
  due_date: "",
};

const EntitySettings = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<iEntity>();
  const entity = useSelector(selectEntity);

  //totals
  const [totalCash, setTotalCash] = useState(0);
  const [totalChecking, setTotalChecking] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);
  const [totalCreditBal, setTotalCreditBal] = useState(0);
  const [totalCreditLimit, setTotalCreditLimit] = useState(0);
  const [totalLoan, setTotalLoan] = useState(0);
  const [totalLoanBal, setTotalLoanBal] = useState(0);

  const cashList = entity.entities.filter((item) => {
    if (item.type === "0") return item;
  });
  const checkingList = entity.entities.filter((item) => {
    if (item.type === "1") return item;
  });
  const creditList = entity.entities.filter((item) => {
    if (item.type === "2") return item;
  });
  const loanList = entity.entities.filter((item) => {
    if (item.type === "3") return item;
  });
  const savingList = entity.entities.filter((item) => {
    if (item.type === "4") return item;
  });

  useEffect(() => {
    let totalCreditBal = 0;
    let totalCreditLimit = 0;
    let totalCash = 0;
    let totalChecking = 0;
    let totalSaving = 0;
    let totalLoan = 0;
    let totalLoanBal = 0;

    cashList.map((item) => {
      totalCash += Number(item.balance);
    });
    setTotalCash(totalCash);

    checkingList.map((item) => {
      totalChecking += Number(item.balance);
    });
    setTotalChecking(totalChecking);

    savingList.map((item) => {
      totalSaving += Number(item.balance);
    });
    setTotalSaving(totalSaving);

    creditList.map((item) => {
      totalCreditBal += Number(item.balance);
      totalCreditLimit += Number(item.credit_limit);
    });
    setTotalCreditBal(totalCreditBal);
    setTotalCreditLimit(totalCreditLimit);

    loanList.map((item) => {
      totalLoanBal += Number(item.balance);
      totalLoan += Number(item.credit_limit);
    });
    setTotalLoanBal(totalLoanBal);
    setTotalLoan(totalLoan);
  }, []);

  const handleOpen = () => {
    setSelectedEntity(undefined);
    setOpen(!open);
  };

  const updateEntity = (entity: iEntity) => {
    setSelectedEntity(entity);
    setOpen(!open);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] boder-bdColor">
      <div className="text-titleColor  ">Manage Entities</div>
      <div className="">
        <div className="">
          <div className="">
            <div className="text-right">
              <Button
                type="button"
                color="blue"
                disabled={open}
                onClick={handleOpen}  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                Add Entity
              </Button>
              <AddEntity
                showNewExpense={open}
                setShowNewExpense={handleOpen}
                onProgress={onProgress}
                selectedEntity={selectedEntity}
              ></AddEntity>
            </div>
          </div>

          <div className="mt-5 p-3 ring-1 ring-gray-300 rounded-md bg-white">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2">
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Name
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Balance
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                </tr>
              </thead>
              <tbody>
                {cashList.length > 0 ? (
                  cashList.map((item, i) => (
                    <tr
                      key={i}
                      className={` ${
                        i < cashList.length - 1 && `border-b-2`
                      }  border-b-gray-100 flex flex-col md:table-row tracking-wide`}
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-3/12">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.type && entityType[Number(item.type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.balance)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateEntity(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-5 text-sm text-gray-700 text-md pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}
                {cashList.length > 0 && (
                  <tr
                    className={`border-b-gray-100 flex flex-col md:table-row tracking-wide bg-gray-100`}
                  >
                    <td className="pt-5 px-2 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      Total
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalCash} />
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td colSpan={3}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-10 p-3 ring-1 ring-gray-300 rounded-md bg-white">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2">
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Name
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Balance
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                </tr>
              </thead>
              <tbody>
                {checkingList.length > 0 ? (
                  checkingList.map((item, i) => (
                    <tr
                      key={i}
                      className={` ${
                        i < checkingList.length - 1 && `border-b-2`
                      }  border-b-gray-100 flex flex-col md:table-row tracking-wide`}
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-3/12">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.type && entityType[Number(item.type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.balance)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateEntity(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-5 text-sm text-gray-700 text-md pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}
                {checkingList.length > 0 && (
                  <tr
                    className={`border-b-gray-100 flex flex-col md:table-row tracking-wide bg-gray-100`}
                  >
                    <td className="pt-5 px-2 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      Total
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalChecking} />
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td colSpan={3}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-10 p-3 ring-1 ring-gray-300 rounded-md bg-white">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2">
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Name
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Balance
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                </tr>
              </thead>
              <tbody>
                {savingList.length > 0 ? (
                  savingList.map((item, i) => (
                    <tr
                      key={i}
                      className={` ${
                        i < savingList.length - 1 && `border-b-2`
                      }  border-b-gray-100 flex flex-col md:table-row tracking-wide`}
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-3/12">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.type && entityType[Number(item.type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.balance)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12"></td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateEntity(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-5 text-sm text-gray-700 text-md pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}
                {savingList.length > 0 && (
                  <tr
                    className={`border-b-gray-100 flex flex-col md:table-row tracking-wide bg-gray-100`}
                  >
                    <td className="pt-5 px-2 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      Total
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalSaving} />
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold"></td>
                    <td colSpan={3}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-10 p-3 ring-1 ring-gray-300 rounded-md bg-white">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2">
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Name
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Balance
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Credit Limit
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    APR(%)
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Due On
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                </tr>
              </thead>
              <tbody>
                {creditList.length > 0 ? (
                  creditList.map((item, i) => (
                    <tr
                      key={i}
                      className={` ${
                        i < creditList.length - 1 && `border-b-2`
                      }  border-b-gray-100 flex flex-col md:table-row tracking-wide`}
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-3/12">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.type && entityType[Number(item.type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.balance)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.credit_limit)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        {item.apr}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.due_date}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateEntity(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-5 text-sm text-gray-700 text-md pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}

                {creditList.length > 0 && (
                  <tr
                    className={`border-b-gray-100 flex flex-col md:table-row tracking-wide bg-gray-100`}
                  >
                    <td className="pt-5 px-2 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      Total
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      {totalCreditBal ? ((totalCreditBal * 100) / totalCreditLimit).toFixed(0) : 0}%
                      used
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalCreditBal} />
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalCreditLimit} />
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-10 p-3 ring-1 ring-gray-300 rounded-md bg-white">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2">
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Name
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Balance Left
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Total
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    APR(%)
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                    Due On
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide"></th>
                </tr>
              </thead>
              <tbody>
                {loanList.length > 0 ? (
                  loanList.map((item, i) => (
                    <tr
                      key={i}
                      className={` ${
                        i < loanList.length - 1 && `border-b-2`
                      }  border-b-gray-100 flex flex-col md:table-row tracking-wide`}
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-3/12">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.type && entityType[Number(item.type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.balance)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        <Currency val={Number(item.credit_limit)} />
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        {item.apr}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12">
                        {item.due_date}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-1/12">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateEntity(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="py-5 text-sm text-gray-700 text-md pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}
                {loanList.length > 0 && (
                  <tr
                    className={`border-b-gray-100 flex flex-col md:table-row tracking-wide bg-gray-100`}
                  >
                    <td className="pt-5 px-2 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      Total
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      {totalLoanBal ? ((totalLoanBal * 100) / totalLoan).toFixed(0) : 0}% left
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalLoanBal} />
                    </td>
                    <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 w-2/12 font-semibold">
                      <Currency val={totalLoan} />
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitySettings;
