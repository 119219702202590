import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iExpenseType } from "../../app/interfaces/iExpenseState";
import { iEntity } from "../../app/interfaces/iEntityState";
import { useCreateExpenseMutation, useRemoveExpenseMutation } from "../../services/expenseApi";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { deleteExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { updateEntity } from "../../features/entitySlice";

interface IFormInput {
  expenseType: string;
  amount: number;
  note: string;
  paymentType: string;
  date: string;
}

type Props = {
  showUpdateExpense: boolean;
  setUpdateExpense: (val: boolean) => void;
  exTypes?: iExpenseType[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
  selectedTransaction: iTransaction;
};

function RemoveExpense({
  showUpdateExpense,
  setUpdateExpense,
  exTypes,
  entities,
  onProgress,
  selectedTransaction
}: Props) {
  const dispatch = useAppDispatch();
  const [removeExpense, { data, isSuccess, isError, isLoading, error }] = useRemoveExpenseMutation();
  const [entityIdForEntityState, setEntityIdForEntityState] = useState(0);
  const [amountForEntityState, setAmountForEntityState] = useState(0);
  const [repeatForm, setRepeatForm] = useState(false);
  const [recurringSwitch, setRecurringSwitch] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    console.log('selectedTransaction: ', selectedTransaction)
    setEntityIdForEntityState(Number(selectedTransaction.from_entity_id));
    // -1 for refund
    setAmountForEntityState(Number(selectedTransaction.amount) * -1);
    //console.log(data);
    await removeExpense({
      id: selectedTransaction.id
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (!repeatForm) {
        reset();
      }
      //update state
      if (data) {
        dispatch(
          deleteExpense({id: data.deleted_id})
        );
      }
      setUpdateExpense(!setUpdateExpense);
      // Update State of Financial Entity
      dispatch(
        updateEntity({
          id: entityIdForEntityState,
          balance: amountForEntityState,
        })
      );

      onProgress(100);
      ToastSuccess(1, "Expense deleted.");
    }
    if (isError) {
      ToastError(2, "Cannot delete expense, please try again: " + Error);
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  const amountCalc = (e: any) => {
    e.preventDefault();
    const re = /^[0-9\b+-.]+/;
    const val = e.target.value;

    if (re.test(val[val.length - 1])) {
      setValue("amount", val);
    } else {
      setValue("amount", val.slice(0, -1));
    }

    if (val[val.length - 1] === " ") {
      const trimmed = val.trim();
      let s;
      let res = 0;
      if (trimmed.includes("+")) {
        s = trimmed.split("+");
        s.map((n: any) => {
          res += parseFloat(n);
        });
      } else if (trimmed.includes("-")) {
        s = trimmed.split("-").reverse();
        s.map((n: any) => {
          res = parseFloat(n) - res;
        });
      }
      setValue("amount", res);
    }
  };

  return (
    <div>
      <Dialog
        open={showUpdateExpense}
        handler={setUpdateExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Do you really want to remove this record?
          </DialogHeader>

          <DialogBody divider className="font-normal"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <p>{selectedTransaction.note}</p>
          </DialogBody>
          <DialogFooter  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <Button
              variant="text"
              color="red"
              onClick={() => setUpdateExpense(!setUpdateExpense)}
              className="mr-1"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="blue" type="submit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <span>Delete</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default RemoveExpense;
