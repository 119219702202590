import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { iRecurringState } from "../app/interfaces/iRecurringState"
import { iRecurringTransaction } from "../app/interfaces/iRecurringTransaction"


const initialState:iRecurringState = {
  recurringTransactions:[]
}

export const recurringSlice = createSlice({
  name: 'recurring',
  initialState: initialState,
  reducers: {
    setRecurring: (state, action: PayloadAction<{rTxn: iRecurringTransaction[]}>) => {
      state.recurringTransactions = action.payload.rTxn;
    },
    removeRecurring: (state, action: PayloadAction<{id: number}>) => {
      const updatedRecurring = state.recurringTransactions.filter((item) => {
        if(item.id !== action.payload.id) {
          return item;
        }
      })
      state.recurringTransactions = updatedRecurring;
    },
    appendRecurring: (state, action: PayloadAction<{rTxn: iRecurringTransaction}>) => {
      state.recurringTransactions.unshift(action.payload.rTxn)
    },
    updateRecurring: (state, action: PayloadAction<{rTxn: iRecurringTransaction}>) => {
      const updatedRecurring = state.recurringTransactions.filter((item) => {
        if(item.id === action.payload.rTxn.id) {
          item = action.payload.rTxn;
        }
      })
      state.recurringTransactions = updatedRecurring;
    },
  }
})

export const selectRecurring = (state: RootState) => state.recurring
export const { setRecurring, removeRecurring, appendRecurring, updateRecurring } = recurringSlice.actions
export default recurringSlice.reducer