import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const entityApi = createApi({
  reducerPath: 'entityApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createEntity: builder.mutation({
      query: (body: {  id: number, name: string, type: string, balance: number, credit_limit: number, apr: number, due_date: number}) => {
        return {
          url: '/v1/entity/add',
          method: 'post',
          body
        }
      }
    }),
    getEntities: builder.mutation({
      query: () => {
        return {
          url: '/v1/entity',
          method: 'get',
        }
      }
    }),
  }),
})


export const { useCreateEntityMutation, useGetEntitiesMutation } = entityApi;