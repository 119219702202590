import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { iExpenseType } from "../../app/interfaces/iExpenseState";
import { iEntity } from "../../app/interfaces/iEntityState";
import { useCreateExpenseMutation } from "../../services/expenseApi";
import { ToastError, ToastSuccess } from "../../util/Toast";
import { useForm, SubmitHandler } from "react-hook-form";
import { addExpense } from "../../features/xpenseSlice";
import { iTransaction } from "../../app/interfaces/iTransaction";
import { updateEntity } from "../../features/entitySlice";
import ToggleSwitch from "../../components/ToggleSwitch";
import { iRecurringTransaction } from "../../app/interfaces/iRecurringTransaction";
import { useRemoveRecurringTransactionsMutation, useUpdateRecurringExpenseMutation } from "../../services/recurringApi";
import { removeRecurring } from "../../features/recurringSlice";
import { formatRecurring } from "../../util/Common";
import { updateRecurring } from "../../features/recurringSlice";

interface IFormInput {
  expenseType: string;
  amount: number;
  note: string;
  paymentType: string;
  date: string;
}

type Props = {
  showNewExpense: boolean;
  setShowNewExpense: (val: boolean) => void;
  exTypes?: iExpenseType[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
  selectedRecurringExpense: iRecurringTransaction;
};

function AddRecurringExpense({
  showNewExpense,
  setShowNewExpense,
  exTypes,
  entities,
  onProgress,
  selectedRecurringExpense
}: Props) {
  const dispatch = useAppDispatch();
  const [createExpense, { data, isSuccess, isError, isLoading, error }] =
    useCreateExpenseMutation();
  const [entityIdForEntityState, setEntityIdForEntityState] = useState(0);
  const [amountForEntityState, setAmountForEntityState] = useState(0);
  const [repeatForm, setRepeatForm] = useState(false);
  const [recurringSwitch, setRecurringSwitch] = useState(false);
  const [removeRecurringTransactions, {}] = useRemoveRecurringTransactionsMutation();
  const [updateRecurringExpense, {}] = useUpdateRecurringExpenseMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setEntityIdForEntityState(Number(data.paymentType));
    setAmountForEntityState(Number(data.amount));

    //update recurring expense if there is any change
    if(selectedRecurringExpense.amount != data.amount || selectedRecurringExpense.expense_type_id != parseInt(data.expenseType) || selectedRecurringExpense.financial_entity_id != parseInt(data.paymentType) || selectedRecurringExpense.note != data.note) {
      await updateRecurringExpense({
        id: selectedRecurringExpense.id,
        financial_entity_id: parseInt(data.paymentType),
        expense_type_id: parseInt(data.expenseType),
        amount: data.amount,
        note: data.note,
        recurring_type: 1
      });
      //TODO: update state here dispatch(updateRecurring())
    }
    //console.log(data);
    await createExpense({
      from_entity_id: parseInt(data.paymentType),
      expense_type_id: parseInt(data.expenseType),
      amount: data.amount,
      note: data.note,
      created_at: data.date,
    });
  };

  useEffect(() => {
    if (!repeatForm) {
      const today = new Date();
      setValue(
        "date",
        today.getUTCFullYear() +
          "-" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(today.getDate()).padStart(2, "0")
      );
    }
    if(selectedRecurringExpense.expense_type && selectedRecurringExpense.expense_type.id)
    setValue('expenseType', selectedRecurringExpense.expense_type.id.toString())
    setValue('amount', Number(selectedRecurringExpense.amount))
    setValue('note', selectedRecurringExpense.note)
    if(selectedRecurringExpense.financial_entity_id && selectedRecurringExpense.financial_entity_id)
      setValue('paymentType', selectedRecurringExpense.financial_entity_id.toString())
    
  }, [showNewExpense]);

  useEffect(() => {
    if (isSuccess) {
      if (!repeatForm) {
        reset();
      }
      //update state
      if (data) {
        const newData_: iTransaction = data[0];
        dispatch(addExpense({ expense: newData_ }));
      }
      setShowNewExpense(!showNewExpense);
      // Update State of Financial Entity
      dispatch(
        updateEntity({
          id: entityIdForEntityState,
          balance: amountForEntityState,
        })
      );

      onProgress(100);
      ToastSuccess(1, "Expense added successfully");
    }
    if (isError) {
      ToastError(2, "Cannot add new expense, please try again: " + Error);
      console.log("Error: ", Error);
    }
  }, [isSuccess, isError]);

  const amountCalc = (e: any) => {
    e.preventDefault();
    const re = /^[0-9\b+-.]+/;
    const val = e.target.value;

    if (re.test(val[val.length - 1])) {
      setValue("amount", val);
    } else {
      setValue("amount", val.slice(0, -1));
    }

    if (val[val.length - 1] === " ") {
      const trimmed = val.trim();
      let s;
      let res = 0;
      if (trimmed.includes("+")) {
        s = trimmed.split("+");
        s.map((n: any) => {
          res += parseFloat(n);
        });
      } else if (trimmed.includes("-")) {
        s = trimmed.split("-").reverse();
        s.map((n: any) => {
          res = parseFloat(n) - res;
        });
      }
      setValue("amount", res);
    }
  };

  const removeRecurringTransaction = async (recurringTransaction: iRecurringTransaction) => {
    await removeRecurringTransactions({id: recurringTransaction.id}).then((res) => {
      const data = "data" in res ? res.data : null;
      if (data) {
        dispatch(removeRecurring({ id: recurringTransaction.id }));
        setShowNewExpense(!showNewExpense);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className="">
      <Dialog
        open={showNewExpense}
        handler={setShowNewExpense}
        className="min-w-5/6 sm:min-w-3/4 md:min-w-fit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader className="text-gray-1000 text-lg text-gray-900"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            New Recurring Expense
          </DialogHeader>

          <DialogBody divider className="font-normal"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div className="w-full text-gray-900 text-sm bg-white m-2">
              <div className="">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Expense Type
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "expenseType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("expenseType", {
                    required: true,
                    maxLength: 100,
                  })}
                >
                  <option value="">Select</option>
                  {exTypes &&
                    exTypes.length > 0 &&
                    exTypes.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.expenseType?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Amount
                </label>
                <input
                  type="text"
                  step="0.01"
                  {...register("amount", {
                    required: true,
                    maxLength: 11,
                  })} 
                  pattern="[0-9]+([,\.][0-9]+)?" inputMode="decimal"
                  className={`bg-gray-50 border-l-4 text-md block w-full p-2.5 outline-none text-gray-800 font-semibold tracking-wider rounded-none ${
                    "amount" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                  onKeyUp={(event) => amountCalc(event)}
                />
                <p className="text-red-900 text-right">
                  {errors?.amount?.type}
                </p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Note
                </label>
                <textarea
                  id=""
                  {...register("note", { required: true, maxLength: 100 })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "note" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                ></textarea>
                <p className="text-red-900 text-right">{errors?.note?.type}</p>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Payment
                </label>
                <select
                  className={`bg-gray-50 border-l-4 text-md text-gray-900  block w-full p-2.5 outline-none rounded-none ${
                    "paymentType" in errors
                      ? "border-red-900"
                      : " border-gray-400"
                  }`}
                  {...register("paymentType", {
                    required: true,
                  })}
                >
                  <option value="">Select</option>
                  {entities &&
                    entities.length > 0 &&
                    entities.map((option) => (
                      <option key={option.id} value={option.id.toString()}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <p className="text-red-900 text-right">
                  {errors?.paymentType?.type}
                </p>
              </div>
              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm font-bold text-gray-600 block mb-1.5 ml-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  {...register("date", { required: true })}
                  className={`bg-gray-50 border-l-4 text-md text-gray-900 block w-full p-2.5 outline-none rounded-none ${
                    "date" in errors ? "border-red-900" : " border-gray-400"
                  }`}
                />
                <p className="text-red-900 text-right">{errors?.date?.type}</p>
              </div>

              <div className="mt-4 mb-2">
                <label
                  htmlFor=""
                  className="text-sm  text-gray-600 block mb-1.5 ml-1"
                >
                  Recurring type: {selectedRecurringExpense.recurring_type && formatRecurring(selectedRecurringExpense.recurring_type)}
                </label>
              </div>
            </div>
          </DialogBody>
          <DialogFooter  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <Button
              variant="text"
              color="brown"
              onClick={() => removeRecurringTransaction(selectedRecurringExpense)}
              className="mr-1"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  <span>Remove</span>
                </Button>
                <Button
              variant="text"
              color="red"
              onClick={() => setShowNewExpense(!showNewExpense)}
              className="mr-1"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                  <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="blue" type="submit"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <span>Add</span>
                </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </div>
  );
}

export default AddRecurringExpense;
