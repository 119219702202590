import { useState } from "react";
import user from "../assets/img/user.png";
import { Button } from "@material-tailwind/react";
import storage from "../app/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAppSelector } from "../app/hooks";
import { selectAuth } from "../features/authSlice";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

function UploadProfileImage() {
  const [file, setFile] = useState<any>();
  const [percent, setPercent] = useState(0);
  const [img, setImg] = useState("");

  const { id } = useAppSelector(selectAuth);

  getDownloadURL(ref(storage, "profile_images/img_" + id))
    .then((url) => {
      console.log("url: ", url);
      setImg(url);
    })
    .catch((error) => {
      // Handle any errors
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length && files[0]) {
      setFile(files[0]);
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(storage, `/profile_images/img_${id}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
        });
      }
    );
  };

  return (
    <div>
      <div className="text-titleColor font-light ">Profile Picture</div>
      <div className="mt-10 mb-10 px-5">
        <img
          src={user}
          alt=""
          className="rounded-full ring-1 ring-gray-400 cursor-pointer hover:opacity-90"
          width={100}
          height={100}
        />
      </div>
      <div>
        <input type="file" onChange={handleChange} accept="/image/*" />
        <p>{percent} "% done"</p>
      </div>
    </div>
  );
}

export default UploadProfileImage;
