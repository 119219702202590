import UserLayout from "../../layouts/UserLayout";
import { Button } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectExpense } from "../../features/xpenseSlice";
import AddExpense from "./AddExpense";
import { Fragment } from "react";
import { iExpenseType } from "../../app/interfaces/iExpenseState";
import { selectEntity } from "../../features/entitySlice";
import { iEntity } from "../../app/interfaces/iEntityState";
import { selectAuth } from "../../features/authSlice";
import Currency from "../../components/Currency";
import { iTransaction } from "../../app/interfaces/iTransaction";
import MonthlyExpenseWidget from "../../components/MonthlyExpenseWidget";
import useTransaction from "../../app/hooks/useTransaction";
import YearsList from "../../components/YearsList";
import RecurringExpenseWidget from "../../components/RecurringExpenseWidget";
import { selectRecurring } from "../../features/recurringSlice";
import OptionButton from "../../components/OptionButton";
import UpdateExpense from "./UpdateExpense";
import RemoveExpense from "./RemoveExpense";

const Expenses = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const expense = useSelector(selectExpense);
  const [loadTransactions] = useTransaction();
  const recurringList = useSelector(selectRecurring);

  let expenseListAll: iTransaction[] = expense.expenses;
  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());
  expenseListAll = expenseListAll.filter((item) => {
    return item.type === "expense";
  });

  const perPage = 20;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(expenseListAll.length);

  const [expenseList, setExpenseList] =
    useState<iTransaction[]>(expenseListAll);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const exTypes = useSelector(selectExpense);
  const entity = useSelector(selectEntity);

  const [exTypesList, setExTypesList] = useState<iExpenseType[]>(
    exTypes.expenseTypes || []
  );

  const [entities, setEntities] = useState<iEntity[]>(entity.entities || []);

  const filterResults = (e: React.FormEvent<HTMLFormElement> | any) => {
    const keyword = e.target.value;
    const regexp = new RegExp(keyword, "i");
    if (keyword) {
      let filterList = expenseListAll.filter((item) => {
        const date = item.formatted_created_at ? item.formatted_created_at : "";
        const type = item.expense_type.name ? item.expense_type.name : "";
        const paymentMethod = item.from_entity.name ? item.from_entity.name : "";
        return (
          regexp.test(date) ||
          regexp.test(item.note) ||
          regexp.test(type) ||
          regexp.test(paymentMethod)
        );
      });
      setExpenseList(filterList);
      setTotalRecords(filterList.length);
    } else {
      setExpenseList(expenseListAll);
    }
  };

  const filterByYear = (e: React.FormEvent<HTMLFormElement> | any) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
  };

  //update expense
  const[showUpdateExpense, setShowUpdateExpense] = useState(false);
  const[showRemoveExpense, setShowRemoveExpense] = useState(false);
  const[selectedTransaction, setSelectedTransaction] = useState<iTransaction>();

  const setOptionAction = (action: string, transaction:iTransaction) => {
    setSelectedTransaction(transaction)
    if(action === "edit") {
      setShowUpdateExpense(true)
    } else if(action === "delete") {
      setShowRemoveExpense(true)
    }
  }

  useEffect(() => {
    if (!auth.reload) {
      navigate("/auth");
    }
    onProgress(100);
  }, []);

  useEffect(() => {
    if (!expense.transactionYearsInState.includes(selectedYear)) {
      loadTransactions(selectedYear);
    }
    const allRecords = expenseListAll.filter((item, i) => {
      if (
        new Date(item.created_at).getUTCFullYear().toString() ===
        selectedYear.toString()
      ) {
        return item;
      }
    });
    setTotalRecords(allRecords.length);
    const records = allRecords.filter((item, i) => {
      const from = currentPage * perPage;
      const to = from + perPage;
      if (
        from <= i &&
        i < to &&
        new Date(item.created_at).getUTCFullYear().toString() ===
          selectedYear.toString()
      ) {
        return item;
      }
    });
    setExpenseList(records);
  }, [expense, currentPage, selectedYear]);

  return (
    <UserLayout>
      <Fragment>
        <div className="w-full">
          <div className="w-full">
            <div className="text-right pt-1 md:pt-0 mx-1 md:ml-3">
              <Button
                type="button"
                color="blue"
                disabled={open}
                onClick={handleOpen} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                New Expense
              </Button>
            </div>
            <AddExpense
              showNewExpense={open}
              setShowNewExpense={handleOpen}
              exTypes={exTypesList}
              entities={entities}
              onProgress={onProgress}
            ></AddExpense>
          </div>

          <div className="w-full flex flex-wrap justify-between">
            {/* Left */}
            <div className="w-full md:w-2/6 py-2 md:pr-2.5 md:py-3">
              
              <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
                <MonthlyExpenseWidget
                  expenseList={expenseListAll}
                  exTypesList={exTypesList}
                />
              </div>

              <RecurringExpenseWidget
                exTypesList={exTypesList}
                entities={entities}
                onProgress={onProgress}
                recurringType={1}
              />
              <RecurringExpenseWidget
                exTypesList={exTypesList}
                entities={entities}
                onProgress={onProgress}
                recurringType={2}
              />
              <RecurringExpenseWidget
                exTypesList={exTypesList}
                entities={entities}
                onProgress={onProgress}
                recurringType={3}
              />
            </div>

            {/* Right */}
            <div className="w-full md:w-4/6 py-2 md:pl-2.5 md:py-3">
              <div className="w-full">
                <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
                  
                  <div className="flex justify-between">
                    <div className="text-titleColor  select-none">
                      Recent expenses
                    </div>
                    <div className="flex text-gray-500  text-xs select-none">
                      <div className="pt-2 pr-2">
                        <span className="text-gray-900 tracking-wider text-xs">
                          {currentPage * perPage + 1}-
                          {currentPage * perPage + perPage > totalRecords
                            ? totalRecords
                            : currentPage * perPage + perPage}{" "}
                          of {totalRecords}
                        </span>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className={`mr-2 p-2 rounded-full ${
                            currentPage > 0
                              ? "text-gray-800 hover:bg-blue-gray-50  cursor-pointer"
                              : "text-gray-400"
                          } `}
                          onClick={() => {
                            if (currentPage > 0)
                              setCurrentPage(currentPage - 1);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className={`cursor-pointer p-2 rounded-full ${
                            (currentPage + 1) * perPage < totalRecords
                              ? "text-gray-800 hover:bg-blue-gray-50  cursor-pointer"
                              : "text-gray-400"
                          }`}
                          onClick={() => {
                            if ((currentPage + 1) * perPage < totalRecords)
                              setCurrentPage(currentPage + 1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <select
                        onChange={filterByYear}
                        name=""
                        className="p-1 ring-1 text-xs ring-gray-300 outline-none text-gray-600 rounded-sm"
                      >
                        <YearsList />
                      </select>
                    </div>
                    <div className="text-right">
                      <input
                        type="text"
                        className="ring-1 ring-gray-300 rounded-sm px-2 py-1 outline-none text-sm text-gray-600 tracking-wide"
                        onKeyUp={filterResults}
                      />
                    </div>
                  </div>

                  <div className="md:pt-5">
                    <div>
                      <table className="w-full table-auto">
                        <thead className="hidden md:table-header-group">
                          <tr className="text-left border-b-2">
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Date
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Description
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Type
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Payment Method
                            </th>
                            <th className="pr-5 py-2 text-gray-600 text-sm tracking-wide">
                              Amount
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {expenseList && expenseList.length > 0 ? (
                            expenseList.map((item, i) => (
                              <tr
                                className="border-b-2 sm:border-b-gray-100 md:border-b-gray-100 flex flex-col md:table-row"
                                key={i}
                              >
                                <td className="pt-5 md:py-4  text-gray-700 text-sm pr-5">
                                  {item.formatted_created_at}
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 pr-5">
                                  {item.note}
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 md:pr-5 flex justify-between md:table-cell">
                                  <span className="md:hidden">Category</span>
                                  <span className="">
                                    {item.expense_type.name}
                                  </span>
                                </td>
                                <td className="py-1 md:py-4 text-sm text-gray-700 flex justify-between md:table-cell">
                                  <span className="md:hidden">
                                    Payment Method
                                  </span>
                                  <span> {item.from_entity.name}</span>
                                </td>
                                <td className="pb-4 md:py-4 text-sm text-gray-700 flex justify-between md:table-cell tracking-wide">
                                  <span className="md:hidden">Amount</span>
                                  <span>
                                    <Currency val={Number(item.amount)} />
                                  </span>
                                </td>
                                <td className="">
                                  <div className="hidden md:flex justify-end">
                                    <OptionButton transaction={item} onAction={setOptionAction}/>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center p-5 text-gray-600 "
                              >
                                no records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedTransaction && (
          <UpdateExpense 
            showUpdateExpense={showUpdateExpense}
            setUpdateExpense={setShowUpdateExpense} 
            exTypes={exTypesList}
            entities={entities}
            onProgress={onProgress}    
            selectedTransaction={selectedTransaction}    
          />
        )}
        {selectedTransaction && (
          <RemoveExpense 
            showUpdateExpense={showRemoveExpense}
            setUpdateExpense={setShowRemoveExpense} 
            exTypes={exTypesList}
            entities={entities}
            onProgress={onProgress}    
            selectedTransaction={selectedTransaction}    
          />
        )}
      </Fragment>
    </UserLayout>

  );
};

export default Expenses;
