import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Currency from "./Currency";
import { iRecurringTransaction } from "../app/interfaces/iRecurringTransaction";
import { iEntity } from "../app/interfaces/iEntityState";
import { removeRecurring, selectRecurring } from "../features/recurringSlice";
import { useAppDispatch } from "../app/hooks";
import {
  useRemoveRecurringTransactionsMutation
} from "../services/recurringApi";
import { iExpenseType } from "../app/interfaces/iExpenseState";
import NewRecurringExpense from "../pages/Expenses/NewRecurringExpense";
import AddRecurringExpense from "../pages/Expenses/AddRecurringExpense";
import { formatRecurring } from "../util/Common";
import { useSelector } from "react-redux";
type props = {
  exTypesList: iExpenseType[];
  entities?: iEntity[];
  onProgress: Dispatch<SetStateAction<number>>;
  recurringType: number
};

function RecurringExpenseWidget({ exTypesList, entities, onProgress, recurringType }: props) {
  const dispatch = useAppDispatch();
  const recurringList = useSelector(selectRecurring);
  const [updateState, setUpdateState] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAREModal, setOpenAREModal] = useState(false);
  const [selectedRecurringExpense, setselectedRecurringExpense] = useState<iRecurringTransaction>(Object);
  const [removeRecurringTransactions, {}] = useRemoveRecurringTransactionsMutation();
  const handleOpen = () => setOpen(!open);
  const handleOpenAREModal = () => setOpenAREModal(!openAREModal);
  const handleRecurringExpense = (recurringTransaction: iRecurringTransaction) => {
    setselectedRecurringExpense(recurringTransaction);
    setOpenAREModal(!openAREModal);
  }
  const removeRecurringTransaction = async (recurringTransaction: iRecurringTransaction) => {
    await removeRecurringTransactions({id: recurringTransaction.id}).then((res) => {
      const data = "data" in res ? res.data : null;
      if (data) {
        dispatch(removeRecurring({ id: recurringTransaction.id }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
    setUpdateState(!updateState);
  }
  const [recurringExpenseList, setRecurringExpenseList] = useState<iRecurringTransaction[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  useEffect(() => {
    let total = 0;
    const rel = recurringList.recurringTransactions.filter((item) => {
      if(item.recurring_type === recurringType && item.type === 'expense')
        return item;
    });
    setRecurringExpenseList(rel);
    rel.map((item) => {
      total = total + Number(item.amount)
    });
    setTotalAmount(total)
  }, [recurringList]);

  return (
    <>
    { (Number(recurringType) === 1 || totalAmount > 0) && (
      <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] border-bdColor">
        <div className="text-titleColor  flex flex-row justify-between">
          <div>Recurring Expense <span className="text-xs">({formatRecurring(recurringType)})</span></div>
          <div>
            <span className="text-xs p-1 px-2 font-normal border-[1px] rounded-md shadow-sm text-gray-700 mx-1 cursor-pointer hover:shadow-md hover:text-gray-600 outline-none"
            onClick={handleOpen}>Create</span>
          </div>
        </div>
        <div className="md:pt-4">
          <ul className="w-full text-gray-800">
            {recurringExpenseList && recurringExpenseList.length > 0 && recurringExpenseList.map((item, n) => (
                <li
                  className="flex justify-between my-1 p-1 py-2 border-b-[1px] border-b-gray-200 text-gray-700 text-sm tracking-wide
                  hover:bg-gray-50 cursor-pointer"
                  key={n}  title={item.expense_type && item.expense_type.name} 
                  onClick={() => handleRecurringExpense(item)}
                >
                  <div className="text-sm first-letter:uppercase">{item.note}</div>
                  <div className="text-sm">
                    <Currency val={item.amount} />
                  </div>
              </li>
            )) }
            {recurringExpenseList && recurringExpenseList.length > 0 && (
              <li
                className="flex justify-between my-1 p-1 py-2 border-b-gray-200 text-gray-700 text-sm tracking-wide"
              >
                <div className="text-sm first-letter:uppercase font-semibold">Total</div>
                <div className="text-sm  font-semibold">
                  <Currency val={totalAmount} />
                </div>
            </li>
            )}
            {recurringExpenseList.length === 0 && (
              <p className="text-sm text-center  text-gray-600">
                no recurring expense yet
              </p>
            )}
          </ul>
        </div>
      
        <NewRecurringExpense
          showNewExpense={open}
          setShowNewExpense={handleOpen}
          exTypes={exTypesList}
          entities={entities}
          onProgress={onProgress}
        />
        <AddRecurringExpense
          showNewExpense={openAREModal}
          setShowNewExpense={handleOpenAREModal}
          exTypes={exTypesList}
          entities={entities}
          onProgress={onProgress}
          selectedRecurringExpense={selectedRecurringExpense}
        />
      </div>
      )}
    </>
  );
}

export default RecurringExpenseWidget;

