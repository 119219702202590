import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { selectIncome } from "../../features/incomeSlice";
import AddIncomeSource from "./AddIncomeSource";
import { payrollType } from "../../app/shared";
import { iIncomeSource } from "../../app/interfaces/iIncomeState";

const IncomeSettings = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedIncomeSource, setSeletedIncomeSource] =
    useState<iIncomeSource>();
  const income = useSelector(selectIncome);

  const handleOpen = () => {
    setSeletedIncomeSource(undefined);
    setOpen(!open);
  };
  const updateIncomeSource = (item: iIncomeSource) => {
    setSeletedIncomeSource(item);
    setOpen(!open);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] boder-bdColor">
      <div className="text-titleColor  ">
        Manage Income Sources
      </div>
      <div className="">
        <div className="">
          <div className="">
            <div className="text-right">
              <Button
                type="button"
                color="blue"
                disabled={open}
                onClick={handleOpen}  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}              >
                Add Income Source
              </Button>
              <AddIncomeSource
                showNewExpense={open}
                setShowNewExpense={handleOpen}
                selectedIncomeSource={selectedIncomeSource}
                onProgress={onProgress}
              ></AddIncomeSource>
            </div>
          </div>

          <div className="mt-5 p-3 ring-1 ring-gray-300 pb-5 bg-white rounded-md">
            <table className="w-full table-auto">
              <thead className="hidden md:table-header-group">
                <tr className="text-left border-b-2 tracking-wide">
                  <th className="pr-5 py-2 text-gray-600 text-sm">Name</th>
                  <th className="pr-5 py-2 text-gray-600 text-sm">
                    Payroll Type
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm">Pay Rate</th>
                  <th className="pr-5 py-2 text-gray-600 text-sm">
                    Auto Transaction
                  </th>
                  <th className="pr-5 py-2 text-gray-600 text-sm"></th>
                </tr>
              </thead>
              <tbody>
                {income.incomeSource.length > 0 ? (
                  income.incomeSource.map((item) => (
                    <tr
                      key={item.id}
                      className="border-b-2 sm:border-b-gray-100 md:border-b-gray-100 flex flex-col md:table-row tracking-wide"
                    >
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5">
                        {item.name}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5">
                        {payrollType[Number(item.payroll_type)]}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5 tracking-wide">
                        {item.amount && `$${item.amount}`}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5">
                        {item.auto_transaction === "1" ? "Yes" : "No"}
                      </td>
                      <td className="pt-5 md:py-2 text-gray-700 text-sm pr-5">
                        <button
                          type="button"
                          className="py-1 px-3 border-2 shadow-sm rounded-md pointer hover:shadow-md"
                          onClick={() => updateIncomeSource(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="py-5 text-md text-gray-700 text-sm pr-5 text-center"
                    >
                      {" "}
                      no records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeSettings;
