import { useSelector } from "react-redux";
import { selectEntity } from "../../features/entitySlice";
import { iEntity } from "../../app/interfaces/iEntityState";

type props = {};
export interface iEntityCreditInfo extends iEntity {
  usagePercentage?: number
  unUsedPercentage?: number
  color?: string
}
function CreditUsageWidget({}: props) {
  const entity = useSelector(selectEntity);


  return (
      <div className="py-1.5  md:pl-3 md:py-2.5 ">
        <div className="bg-white rounded-lg shadow-sm p-3 md:p-5 ring-1 ring-bdColor">
          <div className="text-titleColor   text-sm">Credit Usage</div>

          <div className="pt-3">
            {entity.entities.length > 0 ? entity.entities.map((entity, i) => ( 
              entity.type === '2' && entity.balance && Number(entity.balance) > 0 && entity.credit_limit && 
            
                <div className="py-2" key={entity.id}>
                  <div className=" text-gray-700 text-xs">{entity.name}</div>
                  <div className="flex">
                    <div className="flex w-[95%] mt-1.5 h-[8px] opacity-75 bg-white" title={`Balance: $${entity.balance}`}>
                      <div className={` rounded-l-sm ${((entity.balance * 100) / entity.credit_limit) < 30 ? 'bg-green-600' : ((entity.balance * 100) / entity.credit_limit) >= 70 ? 'bg-red-600' : 'bg-orange-600'}`} style={{width: `${((entity.balance * 100) / entity.credit_limit).toFixed(2)}%`}}></div>
                      <div className={` rounded-r-sm bg-gray-100`} style={{width: `${(100 - (entity.balance * 100) / entity.credit_limit).toFixed(2)}%`}}></div>
                    </div>
                    <div className="text-gray-600 text-xs pl-2">{((entity.balance * 100) / entity.credit_limit).toFixed(2)}%</div>
                  </div>
                </div>
            )) : (
              <p className="text-sm text-center  text-gray-600">
                no credit usage found
              </p>
            )}

            </div>

        </div>
      </div>
  );
}

export default CreditUsageWidget;
