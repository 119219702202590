import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../app/mohmayaApi';

export const incomeApi = createApi({
  reducerPath: 'incomeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createIncomeSource: builder.mutation({
      query: (body: {id: number, name: string, amount: number, payroll_type: string, auto_transaction: string}) => {
        return {
          url: '/v1/income/source/add',
          method: 'post',
          body
        }
      }
    }),
    getIncomeSources: builder.mutation({
      query: () => {
        return {
          url: '/v1/income/source',
          method: 'get',
        }
      }
    }),
    createIncome: builder.mutation({
      query: (body: {financial_entity_id:number, income_source_id:number, amount:number, note:string, date: string, pay_accounts: any[], saveForm: boolean}) => {
        return {
          url: '/v1/income/add',
          method: 'post',
          body
        }
      }
    }),
    getIncome: builder.mutation({
      query: () => {
        return {
          url: '/v1/income',
          method: 'get',
        }
      }
    }),
  }),
})


export const { useCreateIncomeSourceMutation, useGetIncomeSourcesMutation, useCreateIncomeMutation, useGetIncomeMutation } = incomeApi;