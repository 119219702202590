import UserLayout from "../../layouts/UserLayout";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import ExpenseSettings from "./expenseSettings";
import EntitySettings from "./entitySettings";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/authSlice";
import { useNavigate } from "react-router-dom";
import IncomeSettings from "./incomeSettings";
import AccountSettings from "./accountSettings";

const Preferences = ({
  onProgress,
}: {
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    if (!auth.reload) {
      navigate("/auth");
    }
    onProgress(100);
  }, []);

  return (
    <UserLayout>
      <Fragment>
        <div className="w-full">
          <div className="w-full flex flex-wrap justify-between">
            {/* Left */}
            <div className="w-full md:w-2/6 py-2 md:pr-2.5 md:py-3">
              <div className="mb-5 bg-white rounded-lg shadow-sm p-3 md:p-5 border-[1px] boder-bdColor">
                <div className="text-titleColor flex flex-row justify-between">
                  <div>Preferences</div>
                </div>
                <div className="md:pt-5">
                  <ul className="w-full text-gray-800  cursor-pointer">
                    <li
                      className={`py-4 bg-gray-100 p-4 mb-1 rounded-md hover:bg-gray-200" ${
                        currentTab === 1 ? "bg-gray-300" : "bg-gray-100"
                      }`}
                      onClick={() => setCurrentTab(1)}
                    >
                      Manage Entities
                    </li>
                    <li
                      className={`py-4 bg-gray-100 p-4 mb-1 rounded-md hover:bg-gray-200" ${
                        currentTab === 2 ? "bg-gray-300" : "bg-gray-100"
                      }`}
                      onClick={() => setCurrentTab(2)}
                    >
                      Manage Expense
                    </li>
                    <li
                      className={`py-4 bg-gray-100 p-4 mb-1 rounded-md hover:bg-gray-200" ${
                        currentTab === 3 ? "bg-gray-300" : "bg-gray-100"
                      }`}
                      onClick={() => setCurrentTab(3)}
                    >
                      Manage Income
                    </li>
                    <li
                      className={`py-4 bg-gray-100 p-4 mb-1 rounded-md hover:bg-gray-200" ${
                        currentTab === 4 ? "bg-gray-300" : "bg-gray-100"
                      }`}
                      onClick={() => setCurrentTab(4)}
                    >
                      Manage Account
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Right */}
            <div className="w-full md:w-4/6 py-2 md:pl-2.5 md:py-3">
              <div className="w-full">
                {currentTab === 1 && <EntitySettings onProgress={onProgress} />}
                {currentTab === 2 && (
                  <ExpenseSettings onProgress={onProgress} />
                )}
                {currentTab === 3 && <IncomeSettings onProgress={onProgress} />}
                {currentTab === 4 && (
                  <AccountSettings onProgress={onProgress} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </UserLayout>
  );
};

export default Preferences;
